<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        { validator: lengthValidator, trigger: 'blur' },
        { validator: inputValidator, trigger: 'blur' },
        { validator: inputValidator, trigger: 'change' },
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
      <el-input
        ref="input"
        v-model="localValue"
        v-mask="vmask"
        :type="isTextArea ? 'textarea' : 'text'"
        :title="!label ? tooltip: ''"
        :autosize="{minRows: minRows || 2, maxRows: minRows || 2}"
        :name="name"
        :size="size"
        :disabled="readonly"
        :placeholder="placeholder || $locale.main.placeholder.string_field"
        @input="$event => onInput($event)"
      >
      <template v-if="isShowLengthText" slot="suffix"><span>{{lengthString}}/{{showlength}}</span></template>
      </el-input>
      <span v-if="isShowLengthTextarea" class="count">{{lengthString}}/{{showlength}}</span>

    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import maskMixin from '@/mixins/maskMixin.js'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'string_field',
  mixins: [mixin, registryMixin, maskMixin, InputLabel],
  props: {
    label: {
      description: 'Название',
      type: String
    },
    mask: {
      type: String,
      frozen: true
    },
    inputType: {
      type: Object,
      editor: 'InputType',
      default: () => ({
        isMaskGuide: true,
        isSaveMasked: true
      })
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    minRows: {
      description: 'Строк в высоту',
      type: String,
      default: '2',
      options: {
        tooltip: {
          show: true,
          content: 'Для поля "Строка" включить галку "Текстовое поле"'
        }
      }
    },
    isTextArea: {
      type: Boolean,
      description: 'Текстовое поле',
      default: false
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean
    },
    minlength: {
      description: 'Минимальное кол-во символов',
      type: String,
      default: null
    },
    maxlength: {
      description: 'Максимальное кол-во символов',
      type: String,
      default: null
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },
    lengthString () {
      if (this.localValue) {
        return this.localValue.length
      }
      return 0
    },
    isShowLengthTextarea () {
      if (this.isTextArea) {
        if (this.maxlength || this.minlength) {
          return true
        }
      }

      return false
    },
    isShowLengthText () {
      if (!this.isTextArea) {
        if (this.maxlength || this.minlength) {
          return true
        }
      }

      return false
    },
    showlength () {
      if (this.maxlength) {
        return this.maxlength
      }

      return this.minlength
    }
  },
  watch: {
    value (value) {
      this.localValue = value
      this.onInput(this.localValue)
    }
  },
  mounted () {
    if (this.mask) {
      this.$emit('change-property', { name: 'inputType', value: { mode: 'maskSymbolsVmask', maskSymbolsVmask: this.mask, isAdvanced: true } })
      this.$emit('change-property', { name: 'mask', value: null })
    }
  },
  methods: {
    onInput (event) {
      // Вызывается из el-input с заполненным event и из maskMixin.js
      let valueToEmit = event

      if (this.imask) {
        this.localValue = event
        valueToEmit = (this.inputType.isSaveMasked) ? this.imask.value : this.imask.unmaskedValue
      }

      this.$emit('input', valueToEmit)
    },
    lengthValidator (rule, value, callback) {
      // Если не задано минимальное или максимальное значение
      if ((!this.isShowLengthTextarea && !this.isShowLengthText) || !value) {
        return callback()
      }

      let message = ''
      if (this.maxlength && value.length > this.maxlength) {
        message = `Количество символов не может превышать ${this.maxlength}`
        return callback(message)
      }
      if (this.minlength && value.length < this.minlength) {
        message = `Количество символов должно быть больше ${this.minlength}`
        return callback(message)
      }

      return callback()
    },
    inputValidator (rule, value, callback) {
      // Работает только при заполненном "Атрибут"
      let isValid = true
      let message = ''

      if (this.inputType && (this.inputType.type || this.inputType.isAdvanced /* Выбран "Тип поля" или выбран "Расширенный" */)) {
        // Валидатор регулярного выражения
        if (['validatorRegex', 'validatorTemplateRegex'].includes(this.inputType.mode) && this.inputType.validatorRegex) {
          if (!RegExp(this.inputType.validatorRegex, this.inputType.validatorRegexFlags || '').test(this.localValue) && (this.localValue || '').length > 0) {
            console.log(this.label, this.name)
            isValid = false
            message += 'Некорректное значение'
          }
        }

        // Валидатор маски
        if (this.inputType.isValidateMask && this.imask && this.imask.isValid === false) {
          isValid = false
          message += 'Значение не совпадает с маской'
        }
      }

      return (!isValid) ? callback(message) : callback()
    }
  }
}
</script>

<style scoped>
/* * /deep/ .el-form-item__error { */
  /* position: relative; */
/* } */
.el-textarea +.count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px;
}
</style>
